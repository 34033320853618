<template>
  <v-dialog
    persistent
    max-width="290px"
    :value="true"
  >
    <v-sheet class="white">
      <v-card-title class="justify-center mb-3">
        {{ $t('changingBooking') }}
      </v-card-title>
      <v-card-subtitle>
        {{ $t('areYouSureYouWantToChangeBooking') }}
      </v-card-subtitle>
      <v-btn
        min-width="145"
        :color="salesCloudColors.green"
        text
        :loading="processing"
        :disabled="processing"
        @click="confirm"
      >
        {{ $t('confirm') }}
      </v-btn>
      <v-btn
        min-width="145"
        :color="salesCloudColors.red"
        text
        :loading="processing"
        :disabled="processing"
        @click="cancel"
      >
        {{ $t('cancel') }}
      </v-btn>
    </v-sheet>
  </v-dialog>
</template>

<script>
export default {
  name: "ConfirmEventUpdateDialog",
  props: {
    processing: {
      type: Boolean
    }
  },
  methods: {
    confirm() {
      this.$emit('confirm')
    },
    cancel() {
      this.$emit('cancel')
    }
  },
	computed: {
		salesCloudColors() {
			return this.$store.state.salesCloudColors
		}
	}
}
</script>

<style scoped>

</style>
